import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, Link } from "react-router-dom";
import PackageCard from './PackageCard';
import axios from "axios";
import Loading from './Loading';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Tabs from './Tabs';
import toast, { Toaster } from 'react-hot-toast';
import HomePopup from './HomePopup';
import Lanaguage from './Lanaguage';

export default function Home() {
  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate('../' + path, { replace: true });
  };
  const [isShowPopup, setPopup] = useState(true);
  //Handling Loadin"p
  const [isLoading, setIsLoading] = useState(false);
  const [walletAmount, setwalletAmount] = useState(0);
  const [rechargeAmount, setRechargeAmount] = useState(0);
  const [todayIncome, settodayIncome] = useState(0);
  const [isPromoter, setPromoter] = useState("N");
  const [pendingIncome, setpendingIncome] = useState(0);
  const [totalIncome, settotalIncome] = useState(0);
  const [totalDirect, settotalDirect] = useState(0);
  const [totalBusiness, settotalBusiness] = useState(0);
  const [token, setToken] = useState("");
  const [rewardStatus, setrewardStatus] = useState("");
  const [targetBusiness, settargetBusiness] = useState(0);
  const [bonusPer, setbonusPer] = useState(0);
  const [value, setValue] = useState("");
  const [userName, setUserName] = useState("");
  useEffect(() => {
    let URL = "";
    let uName = "";
    let xCode = JSON.parse(localStorage.getItem("UserDetails").hexDecode());
    uName = xCode.ContactNo;
    URL = xCode.ReferralURL;
    setValue(URL + uName);
    setUserName(uName);
    //BindPackage();
    GetWalletAmount();
  }, []);
  const CopyCallBack = (t, r) => {
    if (r == true) {
      toast.success("Invite Code Copied!!");
    }
  }
  const GetWalletAmount = () => {
    setIsLoading(true);
    var param = new Object();
    param.MemberId = JSON.parse(localStorage.getItem("UserDetails").hexDecode()).ClientId;
    param.ActionMode = "GetWalletAmount";
    var obj = new Object();
    obj.procName = 'SenderRegistration';
    obj.Para = JSON.stringify(param);
    axios({
      method: 'POST',
      url: process.env.REACT_APP_WEBAPI_URL,
      data: obj
    }).then(function (res) {
      setIsLoading(false);
      res = res.data;
      setwalletAmount(res[0].WalletAmount);
      setRechargeAmount(res[0].ProductWallet);
      settodayIncome(res[0].PromotionalBalance);
      settotalIncome(res[0].TodayIncome);
      settotalDirect(res[0].TotalDirect);
      settotalBusiness(res[0].TotalBusiness);
      setToken(res[0].Token);
      setrewardStatus(res[0].RewardStatus);
      settargetBusiness(res[0].TargetBusiness);
      setbonusPer(res[0].BonusPercentage);
      setPromoter(res[0].IsPromoter);
      setpendingIncome(res[0].PendingIncome);
    }).catch(function (error) {
      setIsLoading(false);
      console.log(error);
    });
  }
  const ClaimIncome = () => {
    if (window.confirm('Are you sure want to claim?')) {
      setIsLoading(true);
      var Obj = new Object();
      Obj.ClientId = JSON.parse(localStorage.getItem("UserDetails").hexDecode()).ClientId;
      Obj.Token = token;
      var objWithdraw = new Object();
      objWithdraw.procName = 'ClaimIncome';
      objWithdraw.Para = JSON.stringify(Obj);
      axios({
        method: 'POST',
        url: process.env.REACT_APP_WEBAPI_URL,
        data: objWithdraw
      }).then(function (res) {
        setIsLoading(false);
        res = res.data;
        if (res[0].StatusCode == "1") {
          GetWalletAmount();
          toast.success(res[0].Msg);
        }
        else {
          if (res[0].StatusCode == "0") {
            toast.error(res[0].Msg);
          } else {
            toast.error('Something went wrong, plz try again later');
          }
        }
      }).catch(function (error) {
        setIsLoading(false);
      });
    }
  }
  const LogOut = () => {
    localStorage.clear();
    routeChange("login");
  }
  return (
    <>
      <div><Toaster toastOptions={{
        // Define default options
        position: "top-center",
        className: '',
        duration: 5000,
        style: {
          fontSize: '0.28em',
          borderRadius: '10px',
          background: '#333',
          color: '#fff'
        }
      }} /></div>
      {isLoading ? <Loading /> : ""}
      <div className="row myheader" style={{ marginLeft: 0, marginRight: 0 }}>
        <div className="col-4" style={{ padding: '0px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
            <img src='https://cdn-icons-png.freepik.com/512/219/219986.png' style={{ height: 30 }}></img>
            <span style={{ fontSize: 16, color: '#fff', marginLeft: 7 }}>{userName}</span>
          </div>
        </div>
        <div className="col-3" style={{ padding: '0px' }}>
          <Lanaguage></Lanaguage>
        </div>
        <div className="col-3" style={{ padding: '0px', marginTop: '0px' }}>
          <div className='logout' onClick={LogOut}>
            <div style={{ fontSize: 15, color: '#fff' }}>Logout</div>
            <img src="img/power.png" style={{ height: '28px', float: 'right' }} />

          </div>
        </div>
      </div>
      <div className="lease wrap">
        {isShowPopup ?
          <div id="mask">

            <div className="mask-body popupdesign" onclick="stopPropagation()">
              <ol style={{ marginLeft: -3 }}>
                <li style={{ fontSize: 14, marginBottom: 6, fontWeight: 'bold' }}>Minimum Deposit 10 USDT </li>
                <li style={{ fontSize: 14, marginBottom: 6, fontWeight: 'bold' }}>Minimum Withdraw 12 USDT</li>
                <li style={{ fontSize: 14, marginBottom: 6, fontWeight: 'bold' }}>Instant Withdrawal (No Fee)</li>
                <li style={{ fontSize: 14, fontWeight: 'bold' }}>Income credit time daily at 12am dubai timing</li>
              </ol>


              <div className='btnPoup'>
                <p className='para'>Invest more to earn more</p>
                <button onClick={() => window.open('https://t.me/Alphaminingxbt/')}>Group</button>
                {/* <button onClick={() => window.open('https://t.me/')}>Channel</button> */}
                <button onClick={() => window.open('http://t.me/TDilorenzominer/')}>Support</button>
                <button onClick={() => setPopup(false)}><i className='fa fa-close'></i> Close</button>
              </div>

            </div>

          </div>
          : null
        }
        <div className="banner">

          <div className="row optMenu" style={{ background: '#01d2582e' }}>
            <div className="col-3" style={{ maxWidth: '22%', padding: '0px', marginRight: '3%', marginTop: '-18px' }} onClick={() => routeChange('recharge')}>
              <center> <img src="img/depositbank.png" style={{ width: '60%' }} alt />
                <div style={{ fontSize: '0.26em' }}>Deposit</div>
              </center>

            </div>
            <div className="col-3" style={{ maxWidth: '22%', padding: '0px', marginRight: '3%', marginTop: '-18px' }} onClick={() => routeChange('Withdraw')}>
              <center> <img src="img/moneywithdraw.png" style={{ width: '60%' }} alt />
                <div style={{ fontSize: '0.26em' }}>Withdraw</div>
              </center>

            </div>
            {/* <div className="col-2" style={{ padding: '0px', marginRight: '3.33%', marginTop: '-18px' }} onClick={() => routeChange('order')}>
              <center> <img src="img/4340006.png" style={{ width: '60%' }} alt />
                <div style={{ fontSize: '0.26em' }}>Investments</div>
              </center>

            </div> */}
            <div className="col-3" style={{ maxWidth: '22%', padding: '0px', marginRight: '3%', marginTop: '-18px' }} onClick={() => routeChange('team')}>
              <center> <img src="img/teamleader.png" style={{ width: '60%' }} alt />
                <div style={{ fontSize: '0.26em' }}>Team</div>
              </center>

            </div>
            <div className="col-3" style={{ maxWidth: '22%', padding: '0px', marginRight: '3%', marginTop: '-18px' }} onClick={() => window.open('https://t.me/Alphaminingxbt')}>
              <center> <img src="img/telegramgrp.png" style={{ width: '60%' }} alt />
                <div style={{ fontSize: '0.26em' }}>Telegram</div>
              </center>

            </div>
          </div>

        </div>
        <div className='row' onClick={() => routeChange('AboutCompany')} hidden>
          <div className="col-12" style={{ fontSize: '16px', marginTop: '10px' }} ><strong>About Us</strong></div>
          <div className='col-12'>
            <p style={{ fontSize: '0.28em', marginBottom: '10px', color: '#000' }}>Basic Information of the company</p>
            <div className='overlayImg'>
              We are trying to always our platform is providing to you best to best services. We provide to the best portal and products of the online gaming.
            </div>
            <div>
              <center> <img src="https://www.resolutionlawng.com/wp-content/uploads/2020/09/group-of-company-img.jpeg" style={{ width: '100%' }} alt /></center>
            </div>
          </div>

        </div>
        <div className='row'>
          <div className='col-6'>
            <div className='grayblk1'>
              <div style={{ height: '25px', }}><span className='normaltxt'>Deposit</span></div>
              <div className='textFigure'><span>{rechargeAmount} USDT</span></div>
            </div>
          </div>
          <div className='col-6'>
            <div className='grayblk1'>
              <div style={{ height: '25px', }}><span className='normaltxt'>Withdraw</span><span style={{ background: '#28a745', fontSize: 9 }} className='bluetext' onClick={() => routeChange('Withdraw')}>Withdraw Now</span></div>
              <div className='textFigure'><span>{walletAmount} USDT</span></div>
            </div>
          </div>

          <div className='col-6' hidden>
            <div className='grayblk1'>
              <div style={{ height: '25px', }}><span className='normaltxt'>Promotional Reward{todayIncome > 0 ? <span className='bluetext' style={{ backgroundColor: '#28A745' }} onClick={ClaimIncome}>Claim Now</span> : null}</span></div>
              <div className='textFigure' style={{ marginBottom: '5px', position: 'absolute' }}><i className='fa fa-inr'></i><span>{todayIncome}</span></div>
            </div>
          </div>
          <div className='col-6' onClick={() => routeChange('order')}>
            <div className='grayblk1'>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src='img/gpu-mining.png' style={{ height: 40 }}></img>
                <span style={{ fontSize: 16, color: '#000', paddingLeft: 10, fontWeight: 600 }}>My Miners</span>
              </div>
            </div>
          </div>
          <div className='col-6' onClick={() => routeChange('income')}>
            <div className='grayblk1'>
              <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                <img src='img/digital-wallet.png' style={{ height: 40 }}></img>
                <span style={{ fontSize: 16, color: '#000', paddingLeft: 10, fontWeight: 600 }}>Profit History</span>
              </div>
            </div>
          </div>
          <div className='col-6' hidden>
            <div className='grayblk1'>
              <div style={{ height: '25px', }}><span className='normaltxt' style={{ fontSize: '12px', }}>Weekly Direct Business</span></div>
              <div className='textFigure'><i className='fa fa-inr'></i><span>{totalBusiness}</span></div>
            </div>
          </div>
          <div className='col-12' hidden>
            <div className='grayblk1'>
              <div style={{ height: '25px', }}><span className='normaltxt' style={{ fontSize: '14px', }}>Your Reward Status</span></div>

              <div className='textFigure'><span className='bluetext' style={{ backgroundColor: rewardStatus == "Not Achieved" ? '#D23D4C' : "#28A745" }}>{rewardStatus}</span></div>
              <div className='textFigure' style={{ fontSize: '13px', fontWeight: 'normal' }}>Weekly Target Business: <i className='fa fa-inr'></i><span>{targetBusiness}</span></div>

            </div>

          </div>
          <span hidden style={{ fontStyle: 'italic', color: 'rgb(46 49 122)', fontSize: 12, marginLeft: 16 }}>Note: if weekly direct business is <i className='fa fa-inr'></i>10000 then you will get <i className='fa fa-inr'></i>500, if <i className='fa fa-inr'></i>20000 then <i className='fa fa-inr'></i>2000 and if <i className='fa fa-inr'></i>30000 then <i className='fa fa-inr'></i>6000.
          </span>
        </div>
        {isPromoter == "Y" ?
          <div className='row'>
            <div className='col-12'>
              <div className='grayblk1'>
                <div style={{ height: '25px', }}><span className='normaltxt'>Your Total Uncliamed ROI</span>
                  {pendingIncome > 0 ?
                    <span style={{ background: '#bf810f', fontSize: 17 }} className='bluetext' onClick={ClaimIncome}>Claim Now</span> : null}
                </div>
                <div className='textFigure'><span>{pendingIncome} USDT</span></div>
              </div>
            </div>
          </div> : null}
        <div hidden className='row newsRow' style={{ borderRadius: '30px', padding: '7px', margin: 0, marginTop: 5, background: '#EE1A3B', boxShadow: '-1px 1px 12px 0px #ffc10740' }}>


          <div className='col-12' style={{ margin: 0 }} >

            <span style={{ color: '#fff', fontSize: 13 }}>https://totalenergies.agency/signup?ref={userName}</span>
            <CopyToClipboard text={value} onCopy={CopyCallBack}>
              <button style={{ float: 'right', border: 'none', background: '#000', borderRadius: 5 }}><i className='fa fa-copy' style={{ fontSize: 20, color: '#fff' }}></i></button>
            </CopyToClipboard>

          </div>


        </div>

        <div hidden className='row newsRow' style={{ marginLeft: '0px', marginRight: '0px', borderRadius: '7px', background: 'rgba(220, 53, 69, 0.12)' }}>

          <div className='col-2'><img src="img/bell.png" style={{ height: '28px', marginLeft: '-18px' }} /></div>
          <div className='col-10'><marquee style={{ fontSize: 14, color: '#000', fontStyle: 'italic' }}>Welcome to Total Energies, New way  to Invest & Earn</marquee></div>


        </div>
        <div className='row cryptoblock'>
          <div className="col-12" style={{ fontSize: '16px', marginTop: '10px', color: '#000' }} ><center><strong>About AlphaMining</strong></center></div>
          <div className='col-12'>
            <p style={{ fontSize: '11px', marginBottom: '10px', color: '#000' }}>
              AlphaMining is the National Investment Promotion and Facilitation Agency that helps investors looking for investment opportunities.</p>
            <p style={{ fontSize: '13px', marginBottom: '10px', color: '#000' }}>The company registration information is as follows:</p>
            <p style={{ fontSize: '11px', marginBottom: '10px', color: '#000' }}>Company Name: AlphaMining Limited</p>
            <p style={{ fontSize: '11px', marginBottom: '10px', color: '#000' }}>Company Registration Number: 10501962</p>
            <p style={{ fontSize: '11px', marginBottom: '10px', color: '#000' }}>Established: 31st March 2023</p>
            <p style={{ fontSize: '11px', marginBottom: '10px', color: '#000' }}>License Approval Date: 7th Dec 2022</p>
            <p style={{ fontSize: '11px', marginBottom: '10px', color: '#000' }}>Registered capital:  50 Crore</p>
            <center style={{ marginTop: '-12%' }}>
              <a href='img/certificate.pdf?v=1' className='companybtn' type='button' download>Certificate</a>

            </center>
          </div>

        </div>
        <div className='row'>
          <div className='col-12'>
            <Tabs />
          </div>
        </div>
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          .logout{
            display:flex;
            justify-content:space-between;
            align-items: center;
          }
         .myheader{
          height: 50px;
          align-items: center;
          padding: 5px 30px;
          background-color: #212529;
          box-shadow: 0px 3px 5px 2px #cdcdcd;
          margin-bottom: 18px;
          justify-content:space-between;
         }
          .para{
            padding: 0;
            margin: 17px;
            justify-content: center;
            /* background: #AB2EF2; */
            line-height: 25px;
            color: #000;
            border-radius: 39px;
            margin-bottom: 0 !important;
            font-weight: 600 !important;
            padding-left:0px !important;
          }
          .popupdesign{
            background:url(img/back.jpg)!important;
            background-size:cover!important;
            background-position:center;
             padding:40px 17px;
             height: 282px!important;
             width:70%!important;
             border-radius: 10px!important;
             filter: invert(1);
          }
          .companybtn {
            display: inline-block;
            padding: 4px 10px;
            text-align: center;
            border-radius: 15px;
            line-height: 17px;
            color:#fff!important;
            font-size: 12px;
            font-weight: 700;
            background: #000 !important;
            height:30px;
            width:34%;
            color:#000;
            text-decoratrion:none;
        }
          .cryptoblock{
            box-shadow: -1px 1px 12px 0px #6c757d5c;
            margin: 0;
            margin-top: 7px;
            border-radius: 15px;
            padding: 7px;
          }
          .cryptoButton{
            width: 39.46667vw;
            height: 9.33333vw;
            border-radius: 6.66667vw;
            font-size: 4.26667vw;
            font-weight: 700;
            color: #fff;
            background: linear-gradient(1turn,#0b45c9,#1b78d2);
            box-shadow: 1px 1px 0.8vw #c8c9cc;
            border: 1px solid #1b78d2;
            margin: 2.66667vw auto 0;
                  }
          /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.closePopup{
  position: absolute;
  /* margin-top: -79%; */
  /* margin-right: -28%; */
  color: #fff;
  font-size: 22px;
  top: 4px;
  right: 10px;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

          .l_title h1 {
              font-size: 17px;
              font-weight: 500;
          }

          .lease .item>li {
              background: #ffffff;
              border-radius: 8px;
              position: relative;
              margin-bottom: 0.2rem;
              list-style: none;
          }

          .mask-tips>div .text p {
              text-align: center;
              font-size: .24rem;
              line-height: .39rem;
              color: #ffffff;
              font-weight: 400;
              margin: 1px;
          }

          body {
              background-color: #fff !important;
          }

          .lease {
              /* background: #F5F6F7; */
              min-height: 100vh;
              padding: 0 0.3rem 1.8rem 0.3rem;
              background-color: #fff !important;
          }

          .mask {
              position: fixed;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              z-index: 40;
              background: rgba(0, 0, 0, 0.4);
          }

          .mask-body h3 div {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-pack: justify;
              -ms-flex-pack: justify;
              justify-content: space-between;
              padding: 0 0.3rem;
              padding-top: 1rem;
          }

          .layui-layer-wrap p {
              font-size: 14px;
          }

          .layui-layer-title {
              background: #e51837;
              ;
              color: #fff;
              border: none;
              text-align: center;
              padding: 0px;
              border-radius: 10px 10px 0px 0px;
          }

          .layui-layer-title {
              padding: 0 80px 0 20px;
              height: 50px;
              line-height: 50px;
              border-bottom: 1px solid #F0F0F0;
              font-size: 14px;
              color: #fff;
              overflow: hidden;
              border-radius: 2px 2px 0 0;
          }

          .announcement-title[data-v-eebac136] {
              font-size: 20px;
              font-weight: 600;
              color: #fff;
              margin-left: 3px;
              margin-bottom: 0.32rem;
              height: 0.64rem;
              line-height: .64rem;
              padding-left: 5px;
              border-bottom: 3px solid #FF6600;
          }

          .lease .notice>ul li {
              width: 33%;
              text-align: center;
              list-style: none;
              line-height: 13px;
          }

          .tesladiv {
              box-shadow: none !important;
              border-radius: 8px;
              margin-right: 5px;
              margin-bottom: 10px;
              display: flex !important;
              background: #fff !important;
              /* flex-direction: row-reverse; */
              justify-content: space-around;
          }

          div.myitem {
              display: flex;
              flex-wrap: wrap;
              height: auto;
              justify-items: center;
              margin: 0px;
              background: #fff;
              box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
          }

          .myl_left img {
              width: 100%;
              height: 4rem;
          }

          .myl_left {
              text-align: end;
          }

          .myl_left {
              width: 100%;
          }
          .lease .banner {
           
          }
          .myl_right {
              padding: 10px 5px;
              width: 100%;
              background: #fff;
              margin-top: -0px;
          }

          .myl_title {
              margin-bottom: 10px;
          }

          .myl_meta {
              /* display: flex;
              width: 100%;
              flex-wrap: wrap; */
          }

          .mymetal {
              width: 50%;
              margin: 0 !important;
              padding: 0 !important;
              -webkit-tap-highlight-color: transparent;
              float: left;
              height: 20px;
          }

          .myl_desc {
              color: #000;
              font-size: 12px;
              vertical-align: top;
          }

          .myday {
              width: 30%;
              font-size: 12px;
              color: #000;
              vertical-align: top;
          }
          .btn-info.focus, .btn-info:focus{
            box-shadow: none !important;
        }

          .myamount {
              width: 30%;
              font-size: 11px;
              color: #000;
              vertical-align: top;
              font-weight:600
          }

          .mystatus_icon {
              height: 35px !important;
              width: 35px !important;
              position: absolute;
              right: 17px;
          }
          `
        }}
      />
    </>
  )
}
